import moment, { Moment } from 'moment-timezone';
import { DateTime } from "@/stores/liteCMS/cmsData";
import { first, last } from 'lodash';
import { countUnit } from "@/utils/stringUtils";

export const getUtcTime = (date: any) => {
  const utcDate = moment.utc(date);
  if (utcDate.format('mm') === '00') {
    return utcDate.format('h A');
  }
  return utcDate.format('h:mm A');
};

export const getTimezoneTime = (date: any, timezone: any) => {
  const tzDate = moment.tz(date, timezone);
  if (tzDate.format('mm') === '00') {
    return tzDate.format('h A');
  }
  return tzDate.format('h:mm A');
};

const tc = (type: any, value: any) => {
  if (value <= 0) return '';

  switch (type) {
    case 'second':
      return value > 1 ? `${value} seconds` : `${value} second`;
    case 'minute':
      return value > 1 ? `${value} minutes` : `${value} minute`;
    case 'hour':
      return value > 1 ? `${value} hours` : `${value} hour`;
    case 'day':
      return value > 1 ? `${value} days` : `${value} day`;
  }
  return '';
};

export const formattedTimer = (seconds: any) => {
  const secondsInteger = Math.ceil(seconds);

  const minutes = Math.floor(secondsInteger / 60);
  const remainingSeconds = secondsInteger % 60;

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;

  return [
    ['day', days],
    ['hour', remainingHours],
    ['minute', remainingMinutes],
    ['second', remainingSeconds]
  ].map(pair => tc(pair[0], pair[1]))
    .filter(v => Boolean(v))
    .join(', ');
};

export const formatDate = (date: any, timezone = 'UTC', format = 'MMM D, YYYY') => {
  return moment.tz(date, timezone || 'UTC').format(format);
}

export const nullableDate = (str: any) => {
  if (typeof str !== 'string' || str.trim() === '') {
    return null;
  }
  const date = new Date(str);
  return isNaN(date.getTime()) ? null : date;
}

export function listDays(startDate: string | null, endDate: string | null) {
  const start = moment(startDate);
  const end = moment(endDate);
  const dates = [] as Moment[];
  while (start.isSameOrBefore(end, 'day')) {
    dates.push(start.clone());
    start.add(1, 'days');
  }
  return dates;
}

export function datetime(dt: DateTime): Moment | null {
  if (dt.date) {
    const time = dt.time || '00:00:00';
    return moment(`${dt.date}T${time}Z`).tz('UTC');
  }
  return null;
}

export function ymd(dt: any, tz: any = null): string | null {
  return toMoment(dt, tz)?.format('YYYY-MM-DD') ?? null;
}

export function hms(dt: any, tz: any = null): string | null {
  return toMoment(dt, tz)?.format('HH:mm:ss') ?? null;
}

function toMoment(dt: Moment | Date | string | null, tz: string | null = null): Moment | null {
  if (dt) {
    const date = moment(dt);
    if (tz) date.tz(tz);
    return date;
  }
  return null;
}

function sortDates(dates: (string | Date)[]): (string | Date)[] {
  return dates.sort((a, b) => {
    const [dtA, dtB] = [toMoment(a), toMoment(b)];
    return dtA && dtB ? dtA.valueOf() - dtB.valueOf() : 0;
  });
}

export function earliest(...dates: (string | Date)[]): string | Date | undefined {
  return first(sortDates(dates).filter(d => moment(d).isValid()));
}

export function latest(...dates: (string | Date)[]): string | Date | undefined {
  return last(sortDates(dates.filter(d => moment(d).isValid())));
}

export function humanizeDuration(length: number, unit: any) {
  const duration = moment.duration(length, unit);
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  const parts = [] as string[];

  if (hours > 0) {
    parts.push(countUnit(hours, 'hr'));
  }

  if (minutes > 0 || hours === 0) { // Always include minutes if no hours
    parts.push(countUnit(minutes, 'min'));
  }

  return parts.join(' ');
}