export function metaTagContent(name, fallback = '') {
  return document.querySelector(`meta[name=${name}][content]`)?.content ?? fallback;
}

export function dataTagContent(parentId, name, fallback = "") {
  if (
    !parentId ||
    !document.querySelector(`#${parentId}`) ||
    !document.querySelector(`#${parentId}`).dataset[name]
  ) return fallback

  return document.querySelector(`#${parentId}`).dataset[name];
}

// scroll to the first visible element matching the selector, with an optional margin on top.
export function scrollToElement(selector, top = 0) {
  const element = [...document.querySelectorAll(selector)].filter(el => el.offsetParent !== null)[0];
  if (element) {
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - top;
    window.scrollTo({ top: offsetPosition });
  }
}

export function divTag(content) {
  return `<div>${content}</div>`;
}